<template>
  <div>
    <b-card title="Datos del paciente">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Tipo de documento"
            label-for="document_type_id"
          >
            <v-select
              v-model="formData.document_type_id"
              label="text"
              placeholder="Seleccione tipo de documento"
              :options="documentTypes"
              :reduce="option => option.value"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Número de Documento"
            label-for="document_number"
          >
            <b-form-input
              id="document_number"
              v-model="formData.document_number"
              placeholder="Número de Documento"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nombres"
            label-for="first_name"
          >
            <b-form-input
              id="first_name"
              v-model="formData.first_name"
              placeholder="Nombres"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Apellidos"
            label-for="last_name"
          >
            <b-form-input
              id="last_name"
              v-model="formData.last_name"
              placeholder="Apellidos"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Genero"
            label-for="gender_id"
          >
            <v-select
              v-model="formData.gender_id"
              label="text"
              placeholder="Seleccione género"
              :options="genders"
              :reduce="option => option.value"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Fecha de nacimiento"
            label-for="birth-date"
          >
            <b-form-input
              v-model="formData.birth_date"
              type="date"
              placeholder="Fecha de nacimiento"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="EPS"
            label-for="hpc_id"
          >
            <v-select
              v-model="formData.hpc_id"
              label="text"
              placeholder="Seleccione EPS"
              :options="hpcs"
              :reduce="option => option.value"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            {{ titleButton }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
 BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'

export default {
  inject: ['patientsRepository', 'datafieldsRepository'],
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
    BButton,
    BSpinner,
  },
  data() {
    return {
      isBusy: false,
      formData: {
        first_name: '',
        last_name: '',
        document_number: '',
        birth_date: '',
        document_type_id: '',
        gender_id: '',
        hpc_id: '',
      },
      documentTypes: [],
      genders: [],
      hpcs: [],
      locationId: this.$route.params.locationId,
      patientId: this.$route.params.patientId,
      titleButton: 'Registrar paciente',
    }
  },
  validations: {
    formData: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      document_number: {
        required,
      },
      birth_date: {
        required,
      },
      document_type_id: {
        required,
      },
      hpc_id: {
        required,
      },
    },
  },
  async created() {
    const me = this

    const documentTypes = await me.datafieldsRepository.getAll('document_type')
		me.documentTypes = documentTypes.data.map(item => ({
			value: item.id,
			text: item.longName,
		}))

    const genders = await me.datafieldsRepository.getAll('gender')
      me.genders = genders.data.map(item => ({
        value: item.id,
        text: item.longName,
      }))

    const hpcs = await me.datafieldsRepository.getAll('hpc')
    me.hpcs = hpcs.data.map(item => ({
      value: item.id,
      text: item.longName,
    }))

    if (me.patientId !== 'null') me.loadPatient()
  },
  methods: {
    async loadPatient() {
      const me = this
      try {
        const res = await me.patientsRepository.find(me.patientId)
        const { data } = res.data
        me.titleButton = 'Actualizar paciente'
        me.formData = { ...me.formData, ...data }
      } catch (error) {
        // console.log(error)
      }
    },
    async saveData() {
      const me = this
      try {
        me.isBusy = true
        const id = me.formData.id ? me.formData.id : uuidv4()
        const option = me.formData.id ? 'update' : 'save'
        await me.patientsRepository[option](id, me.formData)
        me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
        this.$router.push({
          name: 'register-cama-paciente',
          params: { locationId: me.locationId, patientId: id },
        })
      } catch (error) {
        // console.log(error)
        me.$bvToast.toast('Error', {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
      } finally {
        me.isBusy = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
